import axios from "axios";
export const GENERIC_ERROR_PAGE_MESSAGES = {
  unauthorized:``,
  centerSelectionMissing:`### As an Admin user \n \n You must select a centre to proceed. Please try again`,
  dataFetchFailed:`## The API request cannot be completed \n \n The request failed to reach server or server responded with unprocessable data. \n \n Please check you network and if problem still persist please contact OCR at [support@ocr.org.uk](mailto:support@ocr.org.uk).`,
  invalidRole: `## Restricted access \n \n Only user with role of Exam Officer or Teacher are allowed. \n \n If you require access please contact OCR at [support@ocr.org.uk](mailto:support@ocr.org.uk).`,
  somethingWentWrong: `## Something went wrong \n \n Please try signing out.\n \n And if problem still persist please contact OCR at [support@ocr.org.uk](mailto:support@ocr.org.uk).`,
};

export const PORTAL_USER_ROLES = [
  { role: "INTERNAL_ADMIN", priority: 0 },
  { role: "EXAMS_OFFICER", priority: 1 },
  { role: "TEACHER", priority: 2 },
];

export const DEFAULT_DATE_SPAN={
  startDate:'2021-09-01',
  endDate:'2025-08-31'
}
export const CANDIDATES_LIST_TABLE_HEADER = [
  "Candidate number",
  "OCR candidate number",
  "First name",
  "Last name",
  "Qualification",
  "Specification code",
  "Total GLH required",
  "GLH achieved or in progress",
  /*"Actual GLH",*/
  "Estimated completion date",
  "Qualification result status",
];

export const CANDIDATES_RESULTSLIST_TABLE_HEADER = [
  "Unit number",
  "Unit name",
  "Unit reference number",
  "Unit type",
  "Unit GLH",
  "Assessment type",
  "Achievement status",
];
export const CANDIDATES_DETAILEDVIEW_TABLE_HEADER = [
  "Candidate number",
  "OCR candidate number",
  "First name",
  "Last name",
  "Qualification",
  "Specification code",
  "Total GLH required",
 /* "Actual GLH",*/
 "Estimated completion  date",
  // "Qualification result status",
  "Unit GLH",
  "Unit Type",
  "Unit number",
  "Unit name",
  "Unit achievement status"
];

export const  INELIGIBLE_TABLE_HEADER = [
  "Candidate number",
  "OCR candidate number",
  "First name",
  "Last name",
  "Qualification",
  "Specification code",
  "Total GLH required",
  "GLH achieved or in progress",
  "Estimated completion  date",
  "Qualification result status",
  "Main reason not eligible",
  "Tell us why the candidate is at risk"
];

export const  INELIGIBLE_CANDIDATE_STATUS_HEADER = [
  'Centre number',
  "OCR candidate number",
  "First name",
  "Last name",
  "Qualification",
  "Specification code",
  "Ineligible candidates",
  'Modified By' ,
   'Modified On',
   'Tell us why the candidate is at risk',
    'History',
];


export const CHECKPOINT_STATUS_TABLE_HEADER=[
  'Centre number',
  'Centre name',
  'Modified by',
  'Modified on',
  'Checkpoint status',
  'AuditLog'
]

export const CERTIFICATION_STATUS_TABLE_HEADER = [
  "Centre number",
  "Candidate number",
  "OCR candidate number",
  "First name",
  "Last name",
  "Qualification",
  "Specification code",
  "Intended completion status",
  'Modified By' ,
   'Modified On',
    'History',
];

export const FILTERS={
  regStartDateFilter: "",
    regEndDateFilter: "",
    searchFieldFilter: "",
    subjectLevelFilter: "",
    qualificationFilter: "",
}

export const CERTIFICATION_STATUS_FILTER_OPTIONS=['Summer 2023','No'];
//export const QUALIFICATION_STATUS_FILTER_OPTIONS=['Result achieved','Result not yet achieved'/*,'On track'*/];
export const QUALIFICATION_STATUS_FILTER_OPTIONS=['Achieved',
  'Pending',
  'Not Eligible',
  ];
export const CHECKPOINT_STATUS_FILTER_OPTIONS=['Not Started', 'Completed','In Progress','Ready To Submit']

 export const muleApiInstance = axios.create({
 baseURL:process.env.REACT_APP_MULE_API,
  method:"GET",
  headers:{
    'client_id':process.env.REACT_APP_MULE_CLINET_ID,
    'client_secret':process.env.REACT_APP_MULE_CLEINT_SECRET,
    'X-TRANSACTION-ID':'XREREAWETR' + Math.random() * (1000000-1),
    'Cache-Control': 'no-cache'
      }
 })
export const mulePostApiInstance = axios.create({
 baseURL:process.env.REACT_APP_MULE_API,
  method:"POST",
  headers:{
    'client_id':process.env.REACT_APP_MULE_CLINET_ID,
    'client_secret':process.env.REACT_APP_MULE_CLEINT_SECRET,
    'X-TRANSACTION-ID':'XREREAWETR' + Math.random() * (1000000-1),
    'Cache-Control': 'no-cache'
      }
 })
//  export const muleApiInstance = axios.create({
//   baseURL:`https://apis-sandbox.cambridgeassessment.org.uk/cupa-cambridgetech-xapi-dev/api/v1/`,
//   method:"GET",
//   headers:{
//     client_id:"3c85b2a4d2244d6b8f584d60547dfda4",
//     client_secret:"Db7c80897BE24b329C4b90B752Fee21E",
//     'X-TRANSACTION-ID':'231434413141',
//     'Cache-Control': 'no-cache'
//   }
// })
// export const mulePostApiInstance = axios.create({
//   baseURL:`https://apis-sandbox.cambridgeassessment.org.uk/cupa-cambridgetech-xapi-dev/api/v1/`,
//   method:"POST",
//   headers:{
//     client_id:"3c85b2a4d2244d6b8f584d60547dfda4",
//     client_secret:"Db7c80897BE24b329C4b90B752Fee21E",
//     'X-TRANSACTION-ID':'231434413141'
//   }
// })

// export const muleApiInstance = axios.create({
//   baseURL:`https://apis-sandbox.cambridgeassessment.org.uk/cupa-cambridgetech-xapi-qa2/api/v1/`,
//   method:"GET",
//   headers:{
//     client_id:"01c7e46824bf46f99b042a5228e18aff",
//     client_secret:"4D83Da06F5284490816A4a8489cc06C4",
//     'X-TRANSACTION-ID':'231434413141',
//     'Cache-Control': 'no-cache'
//   }
// })

// export const mulePostApiInstance = axios.create({
//   baseURL:`https://apis-sandbox.cambridgeassessment.org.uk/cupa-cambridgetech-xapi-qa2/api/v1/`,
//   method:"POST",
//   headers:{
//     client_id:"01c7e46824bf46f99b042a5228e18aff",
//     client_secret:"4D83Da06F5284490816A4a8489cc06C4",
//     'X-TRANSACTION-ID':'231434413141'
//   }
// })
// export const INTERNAL_ADMIN_ORGS=['10263'];
export const INTERNAL_ADMIN_ORGS=['99373'];

export const APP_ERROR_CODES={
  unauthorized:{
    code:'ctcpt-401',
  },
  invalidUserRole:{
    code:'ctcpt-403',
  },
  centerSelectionMissing:{
    code:'ctcpt-400'
  },
  apiFailed:{
    code:'ctcpt-500'
  }
}